import React, { useEffect, useState } from 'react'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Slide from 'react-reveal/Slide'
import Riccardo from '../images/Riccardo.jpg'
import Silvia from '../images/Silvia.jpg'
import Zoom from 'react-reveal/Zoom'
import SubscribeModal from '../components/modals/subscribeModal'

const Company = () => {
    const [ modalIsOpen, setModalIsOpen ] = useState(false) 
    const modal = () => {
        setModalIsOpen(true)
    }

    /*useEffect(() => {
        setTimeout(modal,15000)
    },[])*/
    return (
        <div>
            <Layout>
                <Seo title="Company" description="BricoBrowser associated with Ricta Technologies" />
                <header className="animate-fade-in-up bg-no-repeat bg-center bg-cover resize">
                    <div className="py-14 md:py-32 bg-opacity-50 bg-black flex items-center justify-center" style={{background:'rgba(0,0,0,0.5)'}}>
                        <Slide left cascade>
                            <div className="mx-2 text-center">
                                <h1 className="mb-4 text-white font-extrabold text-xl md:text-3xl lg:text-4xl">
                            Welcome to Ricta Technologies
                                </h1>

                                <h1 className="text-white mb-3 font-bold text-xl md:text-2xl lg:text-3xl">
                             As Software Boutique Company we strive to design and develop <br/> innovative solutions to make your life easy and be more productive, <br/> supported by the best customer service.
                                </h1>
                            </div>
                        </Slide>
                    </div>
                </header>
                <section className="grid grid-cols-1 bg-mainColor">
                    <div className="py-4 md:py-10">
                        <h3 className="sm:text-4xl text-2xl text-white my-4">Our Philosophy and Mission </h3>
                        <hr className="horizontalLineWhite"/>

                        <div className="container">
                            <div className="left">
                                <BusinessCenterIcon className="iconsWhite"/>
                            </div>
                            <Slide left cascade>
                                <div className="right">
                                    <p className="text-left text-white sm:text-xl">We use our experience in the field to identify gaps and create solutions that solve daily challenges and optimize your valuable time. 
                                    </p>
                                </div>
                            </Slide>
                        </div>
                        <div className="container">
                            <div className="left">
                                <TrendingUpIcon className="iconsWhite"/>
                            </div>
                            <Slide left cascade>
                                <div className="right">
                                    <p className="text-left text-white sm:text-xl">We leverage the latest technological advancements to build the next generation of products, increasing the quality while driving costs down for the customer.
                                    </p>
                                </div>
                            </Slide>
                        </div>
                    </div>

                </section>
                <section className="bg-yellowColor  py-4">
                    <h3 className="sm:text-4xl text-2xl text-mainColor my-4">Meet the Founders </h3>
                    <h3 className="sm:text-xl text-lg text-mainColor w-2/3 m-auto my-4">Behind our software’s success is the talent and passion of a diverse group of specialists.</h3>
                    <hr className="horizontalLine"/>
                    <div className="grid grid-cols-1 lg:grid-cols-2 ">  
                        <Zoom>
                            <div className="flex justify-center items-center my-4 mx-2">
                                <div className="max-w-lg md:max-h-64 bg-mainColor border-2 border-gray-300 p-6 rounded-3xl tracking-wide shadow-lg">
                                    <div id="header" className="flex items-center mb-4"> 
                                        <img alt="Riccardo T." className="w-20 border-2 border-gray-300" src={Riccardo} />
                                        <div id="header-text" className="leading-5 ml-6 sm">
                                            <h4 id="name" className="text-xl text-white font-semibold">Riccardo T.</h4>
                                            <h5 id="job" className="font-semibold text-white">Co-Founder & CEO</h5>
                                        </div>
                                    </div>
                                    <div className="text-left">
                                        <p className="tracking-wide text-white">Riccardo is a seasoned and passionate Security Expert, he possesses over 20 years of combined experience in Cyber-Physical Security Operations and software development with focus on Digital Forensics, Incident Response and OSINT.</p>
                                    </div>
                                </div>
                            </div>
                        </Zoom>
                        <Zoom>
                            <div className="flex justify-center items-center my-4 mx-2">
                                <div className="max-w-lg md:h-64  bg-mainColor border-2 border-gray-300 p-6 rounded-3xl tracking-wide shadow-lg">
                                    <div id="header" className="flex items-center mb-4"> 
                                        <img alt="Silvia L." className="w-20 border-2 border-gray-300" src={Silvia} />
                                        <div id="header-text" className="leading-5 ml-6 sm">
                                            <h4 id="name" className="text-xl text-white font-semibold">Silvia L.</h4>
                                            <h5 id="job" className="font-semibold text-white">Co-Founder & Administrator</h5>
                                        </div>
                                    </div>
                                    <div className="text-left">
                                        <p className="tracking-wide text-white">Silvia is a Senior Financial Expert as she has over 15 years of combined experience in Business Administration, Risk Management, Fraud Prevention with focus on Digital Innovation and Business Strategy.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Zoom>
                    </div>
                </section>
            </Layout>
            {modalIsOpen && <SubscribeModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}/>}
        </div>
    )

}
export default Company